export const data = [
	{
		to: '/',
		text: 'Home',
		id: 'about',
	},
	{
		to: '/aboutus',
		text: 'About us',
	},
	{
		to: '/contact',
		text: 'Contact',
	},
];
