import React from 'react';
import Form from '../components/Form/Form';

function Contact() {
	return (
		<>
			<Form />
		</>
	);
}

export default Contact;