export const aboutUsData = [

	{
		title: 'Wakulla Coil LLC',
		price: 'Wakulla Coil LLC',
		numAcc: 'test acc',
		description: 'is a female owned and family-operated company with 24 years of excellence. We demand perfection.',
		features: ['test asd', 'testetest', 'Test', 'Testt', 'Ttest'],
	},

];
